"use client";

import React from "react";
import { Milestone } from "~/types/milestones";
import {
  AdvanceCandidateDrawer,
  AdvanceCandidateDrawerProvider,
  InviteScalisCandidateDrawer,
  InviteUploadedCandidateDrawer,
  MatchCandidateDrawer,
  POPUP_TYPE_ADVANCE_CANDIDATE_DRAWER,
  POPUP_TYPE_INVITE_SCALIS_CANDIDATE_DRAWER,
  POPUP_TYPE_INVITE_UPLOADED_CANDIDATE_DRAWER,
  POPUP_TYPE_MATCH_CANDIDATE_DRAWER,
  POPUP_TYPE_UPLOAD_CANDIDATE_DRAWER,
  UploadCandidateDrawer,
} from "~/scalis-components";
import { ApplicationType } from "@prisma/client";
import {
  ApplicationAlreadyExistsModal,
  POPUP_TYPES_APPLICATION_ALREADY_EXISTS_MODAL,
  POPUP_TYPE_ALREADY_INVITED_TO_ANOTHER_JOB_MODAL,
  POPUP_TYPE_ALREADY_INVITED_TO_SAME_JOB_MODAL,
  POPUP_TYPE_ALREADY_MATCHED_TO_JOB_MODAL,
} from "./modals/application-already-exists-modal";

import { MatchCandidateDrawerProvider } from "./match-candidate-drawer/components";

export type PopUpType =
  | typeof POPUP_TYPE_INVITE_SCALIS_CANDIDATE_DRAWER
  | typeof POPUP_TYPE_INVITE_UPLOADED_CANDIDATE_DRAWER
  | typeof POPUP_TYPE_ALREADY_INVITED_TO_SAME_JOB_MODAL
  | typeof POPUP_TYPE_ALREADY_INVITED_TO_ANOTHER_JOB_MODAL
  | typeof POPUP_TYPE_ALREADY_MATCHED_TO_JOB_MODAL
  | typeof POPUP_TYPE_ADVANCE_CANDIDATE_DRAWER
  | typeof POPUP_TYPE_MATCH_CANDIDATE_DRAWER
  | typeof POPUP_TYPE_UPLOAD_CANDIDATE_DRAWER;

interface Props {
  open: PopUpType | null;
  setOpen: (value: PopUpType | null) => void;
  onOpenChange: (identifier: PopUpType | null) => void;
  applicationId: number | null;
  applicationType: ApplicationType | null;
  candidateName: string | null;
  companyId: number | null;
  uploadedCandidateId?: number | null;
  jobSeekerId?: number | null;
  stageId: number | null;
  setApplicationId: (value: number) => void;
  setApplicationType?: (value: ApplicationType) => void;
  setCandidateName: (value: string) => void;
  setCompanyId: (value: number) => void;
  setJobSeekerId?: (value: number) => void;
  setUploadedCandidateId?: (value: number) => void;
  setStageId: (value: number) => void;
  jobNumber?: number | null;
  jobTitle?: string | null;
  setJobNumber: (value?: number | null) => void;
  setJobTitle: (value?: string | null) => void;
  alreadyInvitedToJob: boolean;
  setAlreadyInvitedToJob: (value: boolean) => void;
  recentlyInvitedToAnotherJob: boolean;
  setRecentlyInvitedToAnotherJob: (value: boolean) => void;
  milestones: Milestone[];
}

export const AdvanceCandidateContext = React.createContext<Props>({
  open: null,
  setOpen: () => {},
  onOpenChange: () => {},
  applicationId: null,
  applicationType: null,
  candidateName: null,
  companyId: null,
  jobSeekerId: null,
  uploadedCandidateId: null,
  stageId: null,
  setApplicationId: () => {},
  setApplicationType: () => {},
  setCandidateName: () => {},
  setCompanyId: () => {},
  setStageId: () => {},
  jobNumber: null,
  jobTitle: null,
  setJobNumber: () => {},
  setJobTitle: () => {},
  alreadyInvitedToJob: false,
  setAlreadyInvitedToJob: () => {},
  recentlyInvitedToAnotherJob: false,
  setRecentlyInvitedToAnotherJob: () => {},
  milestones: [],
});

export const AdvanceCandidateProvider: React.FC<
  React.PropsWithChildren<Partial<Props>>
> = ({ children, ...props }) => {
  const [open, setOpen] = React.useState<PopUpType | null>(null);
  const [applicationId, setApplicationId] = React.useState<number | null>(null);
  const [applicationType, setApplicationType] =
    React.useState<ApplicationType | null>(null);
  const [candidateName, setCandidateName] = React.useState<string | null>(null);
  const [companyId, setCompanyId] = React.useState<number | null>(
    props.companyId!,
  );
  const [jobSeekerId, setJobSeekerId] = React.useState<number | null>(null);
  const [uploadedCandidateId, setUploadedCandidateId] = React.useState<
    number | null
  >(null);
  const [stageId, setStageId] = React.useState<number | null>(null);
  const [jobNumber, setJobNumber] = React.useState<number | null | undefined>(
    null,
  );

  const [jobTitle, setJobTitle] = React.useState<string | null | undefined>(
    null,
  );
  const [alreadyInvitedToJob, setAlreadyInvitedToJob] = React.useState(false);
  const [recentlyInvitedToAnotherJob, setRecentlyInvitedToAnotherJob] =
    React.useState(false);
  const [milestones, setMilestones] = React.useState(props?.milestones || []);

  const contextValue = React.useMemo(
    () => ({
      open,
      setOpen,
      onOpenChange: setOpen,
      applicationId,
      applicationType,
      candidateName,
      companyId,
      stageId,
      setApplicationId,
      setApplicationType,
      setCandidateName,
      setCompanyId,
      setStageId,
      jobNumber,
      jobTitle,
      setJobNumber,
      setJobTitle,
      alreadyInvitedToJob,
      setAlreadyInvitedToJob,
      recentlyInvitedToAnotherJob,
      setRecentlyInvitedToAnotherJob,
      milestones,
      jobSeekerId,
      setJobSeekerId,
      uploadedCandidateId,
      setUploadedCandidateId,
    }),
    [
      open,
      setOpen,
      applicationId,
      applicationType,
      candidateName,
      companyId,
      stageId,
      setApplicationId,
      setApplicationType,
      setCandidateName,
      setCompanyId,
      setStageId,
      jobNumber,
      jobTitle,
      setJobNumber,
      setJobTitle,
      alreadyInvitedToJob,
      setAlreadyInvitedToJob,
      recentlyInvitedToAnotherJob,
      setRecentlyInvitedToAnotherJob,
      milestones,
      jobSeekerId,
      setJobSeekerId,
      uploadedCandidateId,
      setUploadedCandidateId,
    ],
  );

  return (
    <AdvanceCandidateContext.Provider value={contextValue}>
      {children}
    </AdvanceCandidateContext.Provider>
  );
};

export const useAdvanceCandidatePopUps = () => {
  const context = React.useContext(AdvanceCandidateContext);
  if (!context) {
    throw new Error(
      "useAdvanceCandidatePopUps must be used within a AdvanceCandidateProvider",
    );
  }
  return context;
};

export const AdvanceCandidatePopUps: React.FC = () => {
  const {
    open,
    onOpenChange,
    applicationId,
    applicationType,
    candidateName,
    companyId,
    jobSeekerId,
    uploadedCandidateId,
    stageId,
    jobNumber,
    jobTitle,
    alreadyInvitedToJob,
    recentlyInvitedToAnotherJob,
  } = React.useContext(AdvanceCandidateContext);

  const handleOpenChange = (identifier: PopUpType) => {
    return (state: boolean) => {
      if (!state) {
        if (identifier === open) onOpenChange(null);
      } else {
        onOpenChange(identifier);
      }
    };
  };

  return (
    <>
      <AdvanceCandidateDrawerProvider
        applicationId={applicationId!}
        stageId={stageId!}
        companyId={companyId!}
      >
        <AdvanceCandidateDrawer
          open={open === POPUP_TYPE_ADVANCE_CANDIDATE_DRAWER}
          onOpenChange={handleOpenChange(POPUP_TYPE_ADVANCE_CANDIDATE_DRAWER)}
          candidateName={candidateName!}
          applicationId={applicationId!}
          stageId={stageId!}
        />
      </AdvanceCandidateDrawerProvider>
      <MatchCandidateDrawerProvider
        companyId={companyId!}
        applicationType={applicationType!}
      >
        <MatchCandidateDrawer
          open={open === POPUP_TYPE_MATCH_CANDIDATE_DRAWER}
          onOpenChange={handleOpenChange(POPUP_TYPE_MATCH_CANDIDATE_DRAWER)}
          candidateName={candidateName!}
          jobSeekerId={jobSeekerId!}
          uploadedCandidateId={uploadedCandidateId!}
        />
      </MatchCandidateDrawerProvider>
      <InviteScalisCandidateDrawer
        open={open === POPUP_TYPE_INVITE_SCALIS_CANDIDATE_DRAWER}
        onOpenChange={handleOpenChange(
          POPUP_TYPE_INVITE_SCALIS_CANDIDATE_DRAWER,
        )}
        applicationId={applicationId!}
        candidateName={candidateName!}
        companyId={companyId!}
      />
      <InviteUploadedCandidateDrawer
        open={open === POPUP_TYPE_INVITE_UPLOADED_CANDIDATE_DRAWER}
        onOpenChange={handleOpenChange(
          POPUP_TYPE_INVITE_UPLOADED_CANDIDATE_DRAWER,
        )}
        applicationId={applicationId!}
        candidateName={candidateName!}
        companyId={companyId!}
        stageId={stageId!}
        alreadyInvitedToJob={alreadyInvitedToJob}
        recentlyInvitedToAnotherJob={recentlyInvitedToAnotherJob}
        jobNumber={jobNumber}
        jobTitle={jobTitle}
      />
      <UploadCandidateDrawer
        companyId={companyId!}
        jobId={jobNumber!}
        open={open === POPUP_TYPE_UPLOAD_CANDIDATE_DRAWER}
        onOpenChange={handleOpenChange(POPUP_TYPE_UPLOAD_CANDIDATE_DRAWER)}
        jobTitle={jobTitle!}
      />
      <ApplicationAlreadyExistsModal
        popupType={open ?? ""}
        open={POPUP_TYPES_APPLICATION_ALREADY_EXISTS_MODAL.includes(open ?? "")}
        onOpenChange={handleOpenChange(open as PopUpType)}
        jobNumber={jobNumber!}
        jobTitle={jobTitle!}
      />
    </>
  );
};
