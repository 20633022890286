import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useContext, useState } from "react";

import { Button } from "~/components/v2/Button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from "~/components/v2/Drawer";
import { useCreateSchedulerInvite } from "~/hooks/scheduler/useSchedulerInvite";
import { useToast } from "~/hooks/useToast";
import { SchedulerLinkForm } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/scheduler-link-drawer/components/scheduler-link-form";
import { SchedulerLinkSchema } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/scheduler-link-drawer/components/scheduler-link-form/scheduler-link-form.types";
import { ScheduleInterviewContext } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/schedule-interview-context";
import { errorHandler } from "~/utils/error";

import { SchedulerLinkDrawerProps } from "./scheduler-link-drawer.types";
import { parseFormData } from "./scheduler-link-drawer.utils";

export const SchedulerLinkDrawer = ({
  candidate,
  open,
  onOpenChange,
}: SchedulerLinkDrawerProps) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { applicationId, stageId } = useContext(ScheduleInterviewContext);

  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const { mutateAsync: createSchedulerInvite, isPending: isLoading } =
    useCreateSchedulerInvite({
      onSuccess: () => {
        setIsSubmit(false);
        onOpenChange(false);
        queryClient.invalidateQueries({
          queryKey: ["candidate-application", applicationId],
        });
      },
      onError: e => {
        errorHandler(toast, e as AxiosError<any>);
      },
    });

  const handleSubmit = async (data: SchedulerLinkSchema) => {
    const parsedData = parseFormData(data, applicationId, stageId);

    await createSchedulerInvite({
      data: [{ ...parsedData }],
    });
  };

  const handleErrorSubmit = (error: any) => {
    setIsSubmit(false);
  };

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      <DrawerContent asChild onResize={() => false}>
        <div>
          <DrawerHeader className="text-center">
            <h1 className="text-base font-semibold text-typography-high-contrast">
              Schedule
            </h1>
          </DrawerHeader>
          <main className="flex flex-col gap-2 px-6 py-4">
            <span className="font-semibold text-typography-high-contrast">
              Send interview link and notify candidate
            </span>
            <SchedulerLinkForm
              isSubmit={isSubmit}
              candidate={candidate}
              onSubmit={(data: SchedulerLinkSchema) => {
                handleSubmit(data);
              }}
              onErrorSubmit={handleErrorSubmit}
            />
          </main>
          <DrawerFooter className="flex items-center justify-end gap-2">
            <DrawerClose asChild>
              <Button variant="outline">Cancel</Button>
            </DrawerClose>
            <Button
              aria-label="Send"
              disabled={isLoading}
              isLoading={isLoading}
              onClick={() => setIsSubmit(true)}
            >
              Send
            </Button>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
};
